import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'

// Shadow the default contact from /gatsby-blog-pages
const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Liên hệ ngay nếu bạn muốn hợp tác xây dựng website cho cá nhân hoặc tổ chức nhé, 
          và tất nhiên là bạn cũng có thể để lại bất kỳ lời nhắn hoặc câu hỏi nào tại đây.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
